import React from "react"
import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../components/shared/Layout/components/Section/Section"
import { Grid } from "@material-ui/core"
import SimpleTabs from "../components/Training/TrainingTabs"
import { PageHeader } from "../components/shared/PageHeader/PageHeader"

const TrainingSection = styled(Section)``

const TrainingPage = () => (
  <Layout fluid={false}>
    <SEO
      title="Výcvik"
      description="S nami zvládnete spraviť Vodičské oprávnenie skupiny B, Kondičné jazdy skupiny B a ak ste zabudli šoférovať ponúkame aj Rekvalifikačný kurz skupiny B."
    />
    <TrainingSection>
      <Container fluid={false}>
        <PageHeader header={"Výcvik"} />
        <Grid>
          <SimpleTabs></SimpleTabs>
        </Grid>
      </Container>
    </TrainingSection>
  </Layout>
)

export default TrainingPage
