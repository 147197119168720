import AppBar from "@material-ui/core/AppBar"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { TabContent, TabContents } from "./components/TabContents"
import TabsMenu from "./components/TabsMenu"

const MyTabContent = styled.div`
  margin: 50px 30px 30px 30px;
`

const SimpleTabs: React.FC<Props> = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const {
    allStrapiCourseType: { courseTypes },
  } = useStaticQuery(graphql`
    query CoursesTypeContent {
      allStrapiCourseType(sort: { order: ASC, fields: id }) {
        courseTypes: nodes {
          name
          content
        }
      }
    }
  `)

  return (
    <div data-sal-duration="700" data-sal="fade-in" data-sal-delay="100" data-sal-easing="ease">
      <AppBar
        position="static"
        style={{
          boxShadow: "none",
          backgroundColor: "lightgreen",
          maxWidth: "950px",
          margin: "0 auto",
        }}
      >
        <TabsMenu
          valueChanged={(value) => setCurrentTabIndex(value)}
          labels={courseTypes.map((courseType) => courseType.name)}
        />
      </AppBar>
      {courseTypes.map((tab: TabContent, index) => (
        <MyTabContent key={index}>
          {currentTabIndex === index && <ReactMarkdown children={courseTypes[currentTabIndex].content} />}
        </MyTabContent>
      ))}
      <div className="tab"></div>
    </div>
  )
}
export default SimpleTabs
