import { useMediaQuery } from "@material-ui/core"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import styled from "styled-components"
import { muiTheme } from "../../../lib/theme"

const MyTab = styled(Tab)`
  background-color: white;
  font-size: 20px;
  line-height: 23px;
  &[aria-selected="true"] {
    background-color: ${({ theme }) => theme.color.primary};
    span {
      color: white;
    }
  }

  span {
    color: ${({ theme }) => theme.color.primary};
    font-size: 16px;
    line-height: 23px;
    text-transform: initial;
  }
`
const MyTabs = styled(Tabs)`
  justify-content: center;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`

interface Props {
  labels: string[]
  valueChanged: (value) => void
}

const TabsMenu: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
    props.valueChanged(newValue)
  }

  const mobile = useMediaQuery(muiTheme.breakpoints.down("md"))

  return (
    <MyTabs
      value={value}
      onChange={handleChange}
      aria-label="simple tabs example"
      variant="fullWidth"
      orientation={mobile ? "vertical" : "horizontal"}
      scrollButtons="auto"
    >
      {props.labels.map((label, index) => (
        <MyTab key={index} className="BUTTONIK" label={label} />
      ))}
    </MyTabs>
  )
}

export default TabsMenu
